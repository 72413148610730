html {
    font-size: 62.5%;
}

#ReceiptsPortal {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #7ac142;
    --bs-secondary: #005051;
    --bs-primary-rgb: 122,193,66;
    --bs-secondary-rgb: 0,80,81;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 0,0,0;
    --bs-body-color-rgb: 33,37,41;
    --bs-body-bg-rgb: 255,255,255;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,.15),hsla(0,0%,100%,0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff
}

#ReceiptsPortal *, ReceiptsPortal :after, ReceiptsPortal :before {
    box-sizing: border-box
}

@media(prefers-reduced-motion:no-preference) {
    #ReceiptsPortal {
        scroll-behavior: smooth
    }
}

#ReceiptsPortal {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-color: #fff;
    background-color: var(--bs-body-bg);
    color: #212529;
    color: var(--bs-body-color);
    font-family: system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-family: var(--bs-body-font-family);
    font-size: 1rem;
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    margin: 0;
    text-align: var(--bs-body-text-align)
}

    #ReceiptsPortal hr {
        background-color: currentColor;
        border: 0;
        color: inherit;
        margin: 1rem 0;
        opacity: .25
    }

#ReceiptsPortal hr:not([size]) {
    height: 1px
}

#ReceiptsPortal .h3, #ReceiptsPortal .h5, #ReceiptsPortal h3, #ReceiptsPortal h5 {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .5rem;
    margin-top: 0
}

#ReceiptsPortal .h3, #ReceiptsPortal h3 {
    font-size: calc(1.3rem + .6vw)
}

@media(min-width: 1200px) {
    #ReceiptsPortal .h3, #ReceiptsPortal h3 {
        font-size: 1.75rem
    }
}

#ReceiptsPortal .h5, #ReceiptsPortal h5 {
    font-size: 1.25rem
}

#ReceiptsPortal p, #ReceiptsPortal ul {
    margin-bottom: 1rem;
    margin-top: 0
}

#ReceiptsPortal ul {
    padding-left: 2rem
}

    #ReceiptsPortal ul ul {
        margin-bottom: 0
    }

#ReceiptsPortal b {
    font-weight: bolder
}

#ReceiptsPortal a {
    color: #0d6efd;
    text-decoration: underline
}

    #ReceiptsPortal a:hover {
        color: #0a58ca
    }

    #ReceiptsPortal a:not([href]):not([class]), #ReceiptsPortal a:not([href]):not([class]):hover {
        color: inherit;
        text-decoration: none
    }

#ReceiptsPortal code {
    word-wrap: break-word;
    color: #d63384;
    direction: ltr;
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    font-size: .875em;
    unicode-bidi: bidi-override
}

#ReceiptsPortal a > code {
    color: inherit
}

#ReceiptsPortal img, #ReceiptsPortal svg {
    vertical-align: middle
}

#ReceiptsPortal table {
    border-collapse: collapse;
    caption-side: bottom
}

#ReceiptsPortal tr {
    border: 0 solid;
    border-color: inherit
}

#ReceiptsPortal label {
    display: inline-block
}

#ReceiptsPortal button {
    border-radius: 0
}

    #ReceiptsPortal button:focus:not(:focus-visible) {
        outline: 0
    }

#ReceiptsPortal button, #ReceiptsPortal input, #ReceiptsPortal select {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0
}

#ReceiptsPortal button, #ReceiptsPortal select {

    text-transform: none
}

#ReceiptsPortal [role=button] {
    cursor: pointer
}

#ReceiptsPortal select {
    word-wrap: normal

}

    #ReceiptsPortal select:disabled {
        opacity: 1
    }

#ReceiptsPortal [type=button], #ReceiptsPortal [type=submit], button {
    -webkit-appearance: button
}

    #ReceiptsPortal [type=button]:not(:disabled), #ReceiptsPortal [type=submit]:not(:disabled), #ReceiptsPortal button:not(:disabled) {
        cursor: pointer
    }

#ReceiptsPortal ::-moz-focus-inner {
    border-style: none;
    padding: 0
}

#ReceiptsPortal ::-webkit-datetime-edit-day-field, #ReceiptsPortal ::-webkit-datetime-edit-fields-wrapper, #ReceiptsPortal ::-webkit-datetime-edit-hour-field, #ReceiptsPortal ::-webkit-datetime-edit-minute, #ReceiptsPortal ::-webkit-datetime-edit-month-field, #ReceiptsPortal ::-webkit-datetime-edit-text, #ReceiptsPortal ::-webkit-datetime-edit-year-field {
    padding: 0
}

#ReceiptsPortal ::-webkit-inner-spin-button {
    height: auto
}

#ReceiptsPortal ::-webkit-search-decoration {
    -webkit-appearance: none
}

#ReceiptsPortal ::-webkit-color-swatch-wrapper {
    padding: 0
}

#ReceiptsPortal ::file-selector-button {
    font: inherit
}

#ReceiptsPortal ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

#ReceiptsPortal [hidden] {
    display: none !important
}

#ReceiptsPortal .lead {
    font-size: 1.25rem;
    font-weight: 300
}

#ReceiptsPortal .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: .75rem;
    padding-left: var(--bs-gutter-x,.75rem);
    padding-right: .75rem;
    padding-right: var(--bs-gutter-x,.75rem);
    width: 100%
}

@media(min-width: 576px) {
    #ReceiptsPortal .container {
        max-width: 540px
    }
}

@media(min-width: 768px) {
    #ReceiptsPortal .container {
        max-width: 720px
    }
}

@media(min-width: 992px) {
    #ReceiptsPortal .container {
        max-width: 960px
    }
}

@media(min-width: 1200px) {
    #ReceiptsPortal .container {
        max-width: 1140px
    }
}

@media(min-width: 1400px) {
    #ReceiptsPortal .container {
        max-width: 1320px
    }
}

#ReceiptsPortal .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x)*-.5);
    margin-right: calc(var(--bs-gutter-x)*-.5);
    margin-top: calc(var(--bs-gutter-y)*-1)
}

    #ReceiptsPortal .row > * {
        flex-shrink: 0;
        margin-top: var(--bs-gutter-y);
        max-width: 100%;
        padding-left: calc(var(--bs-gutter-x)*.5);
        padding-right: calc(var(--bs-gutter-x)*.5);
        width: 100%
    }

#ReceiptsPortal .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%
}

#ReceiptsPortal .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

#ReceiptsPortal .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

@media(min-width: 576px) {
    #ReceiptsPortal .col-sm {
        flex: 1 0
    }

    #ReceiptsPortal .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    #ReceiptsPortal .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
}

#ReceiptsPortal .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: #e4f2d9;
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0,0,0,.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0,0,0,.075);
    border-color: #dee2e6;
    color: #212529;
    margin-bottom: 1rem;
    vertical-align: top;
    width: 100%
}

#ReceiptsPortal .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
}

#ReceiptsPortal .table > :not(caption) > * > * {
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    
}

#ReceiptsPortal .table > :not(:first-child) {
    border-top: 2px solid
}

#ReceiptsPortal .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0
}

#ReceiptsPortal .table-borderless > :not(:first-child) {
    border-top-width: 0
}

#ReceiptsPortal .form-label {
    margin-bottom: .5rem
}

#ReceiptsPortal .col-form-label {
    font-size: inherit;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: calc(.375rem + 1px);
    padding-top: calc(.375rem + 1px);
}

#ReceiptsPortal .form-control {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%
}

@media(prefers-reduced-motion:reduce) {
    #ReceiptsPortal .form-control {
        transition: none
    }
}

#ReceiptsPortal .form-control[type=file] {
    overflow: hidden
}

#ReceiptsPortal .form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

#ReceiptsPortal .form-control:focus {
    background-color: #fff;
    border-color: #7ac142;
    box-shadow: 0 0 0 .075rem #7ac142;
    color: #212529;
    outline: 0
}

#ReceiptsPortal .form-control::-webkit-date-and-time-value {
    height: 1.5em
}

#ReceiptsPortal .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

#ReceiptsPortal .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

#ReceiptsPortal .form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

#ReceiptsPortal .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1
}

#ReceiptsPortal .form-control::file-selector-button {
    -webkit-margin-end: .75rem;
    background-color: #e9ecef;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: #212529;
    margin: -.375rem -.75rem;
    margin-inline-end: .75rem;
    padding: .375rem .75rem;
    pointer-events: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    #ReceiptsPortal .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    #ReceiptsPortal .form-control::file-selector-button {
        transition: none
    }
}

#ReceiptsPortal .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
}

#ReceiptsPortal .form-control::-webkit-file-upload-button {
    -webkit-margin-end: .75rem;
    background-color: #e9ecef;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: #212529;
    margin: -.375rem -.75rem;
    margin-inline-end: .75rem;
    padding: .375rem .75rem;
    pointer-events: none;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    #ReceiptsPortal .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
}

#ReceiptsPortal .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

#ReceiptsPortal .input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%
}

    #ReceiptsPortal .input-group > .form-control {
        flex: 1 1 auto;
        min-width: 0;
        position: relative;
        width: 1%
    }

        #ReceiptsPortal .input-group > .form-control:focus {
            z-index: 3
        }

    #ReceiptsPortal .input-group .btn {
        position: relative;
        z-index: 2
    }

        #ReceiptsPortal .input-group .btn:focus {
            z-index: 3
        }

#ReceiptsPortal .input-group-text {
    align-items: center;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #212529;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    text-align: center;
    white-space: nowrap
}

#ReceiptsPortal .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

#ReceiptsPortal .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px
}

#ReceiptsPortal .btn {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: #212529;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    text-align: center;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle
}

@media(prefers-reduced-motion:reduce) {
    #ReceiptsPortal .btn {
        transition: none
    }
}

#ReceiptsPortal .btn:hover {
    color: #212529
}

#ReceiptsPortal .btn:focus {
    box-shadow: 0 0 0 .075rem #7ac142;
    outline: 0
}

#ReceiptsPortal .btn.disabled, #ReceiptsPortal .btn:disabled {
    opacity: .65;
    pointer-events: none
}

#ReceiptsPortal .btn-primary {
    background-color: #7ac142;
    border-color: #7ac142;
    color: #fff
}

    #ReceiptsPortal .btn-primary:focus, #ReceiptsPortal .btn-primary:hover {
        background-color: #68a438;
        border-color: #629a35;
        color: #fff
    }

#ReceiptsPortal #ReceiptsPortal .btn-primary:focus {
    box-shadow: 0 0 0 .075rem rgba(142,202,94,.5)
}

#ReceiptsPortal .btn-primary.active, #ReceiptsPortal .btn-primary:active {
    background-color: #629a35;
    border-color: #5c9132;
    color: #fff
}

    #ReceiptsPortal .btn-primary.active:focus, #ReceiptsPortal .btn-primary:active:focus {
        box-shadow: 0 0 0 .075rem rgba(142,202,94,.5)
    }

#ReceiptsPortal .btn-primary.disabled, #ReceiptsPortal .btn-primary:disabled {
    background-color: #7ac142;
    border-color: #7ac142;
    color: #fff
}

#ReceiptsPortal .btn-secondary {
    background-color: #005051;
    border-color: #005051;
    color: #fff
}

    #ReceiptsPortal .btn-secondary:focus, #ReceiptsPortal .btn-secondary:hover {
        background-color: #004445;
        border-color: #004041;
        color: #fff
    }

    #ReceiptsPortal .btn-secondary:focus {
        box-shadow: 0 0 0 .075rem rgba(38,106,107,.5)
    }

    #ReceiptsPortal .btn-secondary.active, #ReceiptsPortal .btn-secondary:active {
        background-color: #004041;
        border-color: #003c3d;
        color: #fff
    }

        #ReceiptsPortal .btn-secondary.active:focus, #ReceiptsPortal  .btn-secondary:active:focus {
            box-shadow: 0 0 0 .075rem rgba(38,106,107,.5)
        }

    #ReceiptsPortal .btn-secondary.disabled, #ReceiptsPortal .btn-secondary:disabled {
        background-color: #005051;
        border-color: #005051;
        color: #fff
    }

#ReceiptsPortal .btn-outline-secondary {
    border-color: #005051;
    color: #005051
}

    #ReceiptsPortal .btn-outline-secondary:hover {
        background-color: #005051;
        border-color: #005051;
        color: #fff
    }

    #ReceiptsPortal .btn-outline-secondary:focus {
        box-shadow: 0 0 0 .075rem rgba(0,80,81,.5)
    }

    #ReceiptsPortal .btn-outline-secondary.active, #ReceiptsPortal .btn-outline-secondary:active {
        background-color: #005051;
        border-color: #005051;
        color: #fff
    }

        #ReceiptsPortal .btn-outline-secondary.active:focus, #ReceiptsPortal .btn-outline-secondary:active:focus {
            box-shadow: 0 0 0 .075rem rgba(0,80,81,.5)
        }

    #ReceiptsPortal .btn-outline-secondary.disabled, #ReceiptsPortal .btn-outline-secondary:disabled {
        background-color: transparent;
        color: #005051
    }

#ReceiptsPortal .btn-sm {
    border-radius: .2rem;
    font-size: .875rem;
    padding: .25rem .5rem
}

#ReceiptsPortal .dropdown {
    position: relative
}

#ReceiptsPortal .nav {
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

#ReceiptsPortal .card, #ReceiptsPortal .nav {
    display: flex
}

#ReceiptsPortal .card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    flex-direction: column;
    min-width: 0;
    position: relative
}

    #ReceiptsPortal .card > hr {
        margin-left: 0;
        margin-right: 0
    }

#ReceiptsPortal .pagination {
    display: flex;
    list-style: none;
    padding-left: 0
}

#ReceiptsPortal .page-link {
    background-color: #fff;
    border: 1px solid #dee2e6;
    color: #005051;
    display: block;
    position: relative;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    #ReceiptsPortal .page-link {
        transition: none
    }
}

#ReceiptsPortal .page-link:hover {
    border-color: #dee2e6;
    z-index: 2
}

#ReceiptsPortal .page-link:focus, #ReceiptsPortal .page-link:hover {
    background-color: #e9ecef;
    color: #005051
}

#ReceiptsPortal .page-link:focus {
    box-shadow: 0 0 0 .075rem #7ac142;
    outline: 0;
    z-index: 3
}

#ReceiptsPortal .page-item:not(:first-child) .page-link {
    margin-left: -1px
}

#ReceiptsPortal .page-item.active .page-link {
    background-color: #7ac142;
    border-color: #7ac142;
    color: #fff;
    z-index: 3
}

#ReceiptsPortal .page-item.disabled .page-link {
    background-color: #ebebe4;
    border-color: #dee2e6;
    color: #6c757d;
    pointer-events: none
}

#ReceiptsPortal .page-link {
    padding: .375rem .75rem
}

#ReceiptsPortal .page-item:first-child .page-link {
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem
}

#ReceiptsPortal .page-item:last-child .page-link {
    border-bottom-right-radius: .25rem;
    border-top-right-radius: .25rem
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

#ReceiptsPortal .modal {
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1055
}

#ReceiptsPortal .modal-dialog {
    margin: .5rem;
    margin-top: 24vh;
    pointer-events: none;
    position: relative;
    width: auto
}

#ReceiptsPortal .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

#ReceiptsPortal .modal-content {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%
}

#ReceiptsPortal .modal-header {
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 1rem
}

#ReceiptsPortal .modal-title {
    line-height: 1.5;
    margin-bottom: 0
}

#ReceiptsPortal .modal-body {
    flex: 1 1 auto;
    padding: 1rem;
    position: relative
}

#ReceiptsPortal .modal-footer {
    align-items: center;
    border-bottom-left-radius: calc(.3rem - 1px);
    border-bottom-right-radius: calc(.3rem - 1px);
    border-top: 1px solid #dee2e6;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: .75rem
}

    #ReceiptsPortal .modal-footer > * {
        margin: .25rem
    }

@media(min-width: 576px) {
    #ReceiptsPortal .modal-dialog {
        margin: 1.75rem auto;
        margin-top: 24vh;
        max-width: 500px
    }
}

#ReceiptsPortal .tooltip {
    word-wrap: break-word;
    display: block;
    font-family: system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-family: var(--bs-font-sans-serif);
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    margin: 0;
    opacity: 0;
    position: absolute;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: 1080
}

    #ReceiptsPortal .tooltip.show {
        opacity: .9
    }

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

#ReceiptsPortal .spinner-border {
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    border: .25em solid;
    border-radius: 50%;
    border-right: .25em solid transparent;
    display: inline-block;
    height: 2rem;
    vertical-align: -.125em;
    width: 2rem
}

#ReceiptsPortal .spinner-border-sm {
    border-width: .2em;
    height: 1rem;
    width: 1rem
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@media(prefers-reduced-motion:reduce) {
    #ReceiptsPortal .spinner-border {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s
    }
}

#ReceiptsPortal .placeholder {
    background-color: currentColor;
    cursor: wait;
    display: inline-block;
    min-height: 1em;
    opacity: .5;
    vertical-align: middle
}

.placeholder.btn:before {
    content: "";
    display: inline-block
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

@-webkit-keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

@keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

#ReceiptsPortal .d-flex {
    display: flex !important
}

#ReceiptsPortal .w-25 {
    width: 25% !important
}

#ReceiptsPortal .w-100 {
    width: 100% !important
}

#ReceiptsPortal .justify-content-start {
    justify-content: flex-start !important
}

#ReceiptsPortal .justify-content-end {
    justify-content: flex-end !important
}

#ReceiptsPortal .justify-content-center {
    justify-content: center !important
}

#ReceiptsPortal .justify-content-around {
    justify-content: space-around !important
}

#ReceiptsPortal .align-self-center {
    align-self: center !important
}

#ReceiptsPortal .mt-auto {
    margin-top: auto !important
}

#ReceiptsPortal .p-2 {
    padding: .5rem !important
}

#ReceiptsPortal .pt-1 {
    padding-top: .25rem !important
}

#ReceiptsPortal .pt-2 {
    padding-top: .5rem !important
}

#ReceiptsPortal .pe-5 {
    padding-right: 3rem !important
}

#ReceiptsPortal .pb-2 {
    padding-bottom: .5rem !important
}

#ReceiptsPortal .pb-3 {
    padding-bottom: 1rem !important
}

#ReceiptsPortal .ps-2 {
    padding-left: .5rem !important
}

#ReceiptsPortal a:hover {
    cursor: pointer
}

#ReceiptsPortal .container {
    align-items: center;
    display: grid;
    float: none;
    height: auto;
    justify-content: center
}

#ReceiptsPortal .card, #ReceiptsPortal .card-results {
    background-color: #fff;
    border: none;
    border-radius: 20px;
    padding: 20px
}

#ReceiptsPortal .card-results {
    height: auto;
    width: 70vw
}

#ReceiptsPortal .form-label {
    padding-top: 15px
}

#ReceiptsPortal .modal {
    align-items: center;
    display: block;
    height: 100vh;
    justify-content: center;
    visibility: hidden
}

#ReceiptsPortal .modal-show {
    background-color: hsla(0,0%,66%,.8);
    transition: opacity .2s linear;
    visibility: visible
}

#ReceiptsPortal .modal-content {
    opacity: 0
}

#ReceiptsPortal .modal-show .modal-content {
    opacity: 1;
    transition: .2s linear
}

@media only screen and (max-width: 640px) {
    #ReceiptsPortal .card-results {
        width: 100vw
    }
}

#ReceiptsPortal .responsiveTable {
    width: 100%;
}

#ReceiptsPortal .responsiveTable td .tdBefore {
    display: none;
}

#ReceiptsPortal .responsiveTable th {
    text-align: left;
}

@media screen and (max-width: 40em) {
    /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

    #ReceiptsPortal .responsiveTable table, #ReceiptsPortal .responsiveTable thead, #ReceiptsPortal .responsiveTable tbody, #ReceiptsPortal .responsiveTable th, #ReceiptsPortal .responsiveTable td, #ReceiptsPortal .responsiveTable tr {
        display: block;
    }

    #ReceiptsPortal .responsiveTable thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        border-bottom: 2px solid #333;
    }

    #ReceiptsPortal .responsiveTable tbody tr {
        border: 1px solid #000;
        padding: .25em;
    }

    #ReceiptsPortal .responsiveTable td.pivoted {
        /* Behave like a "row" */
        border: none !important;
        position: relative;
        padding-left: calc(25% + 10px) !important;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    #ReceiptsPortal .responsiveTable td .tdBefore {
        /* Now like a table header */
        position: absolute;
        display: block;
        /* Top/left values mimic padding */
        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 600;
    }
    #ReceiptsPortal .col-form-label {
        text-align: start;
    }

}

